import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { BrowserView, MobileView, isBrowser } from 'react-device-detect'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const MobileDefaultLayout = React.lazy(() => import('./layout/MobileDefaultLayout'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          {isBrowser ? (
            <BrowserView>
              <Routes>
                <Route path="/*" name="Home" element={<DefaultLayout />} />
              </Routes>
            </BrowserView>
          ) : (
            <MobileView>
              <Routes>
                <Route
                  path="/*"
                  name="Home"
                  element= {<MobileDefaultLayout />}
                />
              </Routes>
            </MobileView>
          )}
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
